<template>
  <div class="hide_mask"></div>
</template>
<script>
import { sendcodeGithub } from "@/network/gitlab";

export default {
  mounted() {
    const code = this.$route.query.code;
    sendcodeGithub({
      projectId: localStorage.getItem("github_pid"),
      code: code,
    })
      .then((res) => {
        localStorage.setItem("githubConfigId", res);
        window.close();
      })
      .catch((error) => {
        this.$message({
          type: "warning",
          message: this.$t("addAuthentication.index"),
        });
      });
  },
};
</script>
<style lang="scss" scoped>
.hide_mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  left: 0;
  top: 0;
}
</style>
